import axios from "@/config/httpConfig";
import store from "@/store";


// 获取应用程序列表
export function getApplicationList(data) {
  return axios.post('/application/getApplicationList', data)
}

/**
 * 获取主页列表，返回对应页面的ID，给请求页面布局接口使用
 * 需要在请求时，传入当前页面的type 进行判断返回对应的id
 * 当前页的type怎么确定？
 * 在network中查看 （getHomePageList）的请求返回的type，在主页create请求时传入即可！
 * 详情查看对比标准主页
 * TODO 只需在标准主页中查看传入的pageType,可以看到返回的页面列表
 * id: '', // 应用程序id，默认传空
 */
export async function getHomePageListToCurrentPageObjectData(pageType) {
  /**
    * userinfo中 ispartner:true 代表是伙伴云，
    * 请求页面布局（getHomePageList） 不传 id
    */
  let { ispartner } = store.state.userInfoObj
  if (ispartner) {
    // 如果是伙伴云
    let { data } = await axios.post("/home/getHomePageList", {});
    return {
      // 当前主页对象
      homePageObjectData: data.find((item) => item.type === pageType) || {},
      // 全部主页对象列表
      HomePageListData: data,
      // 简档主页名称
      rolePageDropDownNameObject: data.find((item) => {
        if (item.type === "profile") {
          return item.name;
        }
      }),
    };
  }
  /**
   * 应用程序id
   * 在（Home） 组件中进行请求保存
   */
  let id = store.state.home.homeApplicationId
  if (!id) {
    let res = await getApplicationList()
    id = res.data.find((item) => item.appState === "1").id;
  }
  let { data } = await axios.post("/home/getHomePageList", { id });
  return {
    // 当前主页对象
    homePageObjectData: data.find((item) => item.type === pageType) || {},
    // 全部主页对象列表
    HomePageListData: data,
    // 简档主页名称
    rolePageDropDownNameObject: data.find((item) => {
      if (item.type === "profile") {
        return item.name;
      }
    }),
  };
}


// 保存主页组件布局信息
export function saveHomePageComponentsLayout(data) {
  return axios.post("/home/saveHomePageComponents", data);
}
// 获取主页组件布局
export function getHomePageLayout(data) {
  return axios.post("/home/getHomePageLayout", data);
}
// 获取主页组件数据
export function getHomePageData(data) {
  return axios.post("/home/getHomePageData", data);
}
// 删除主页
export function deleteHomePage(data) {
  return axios.post("/home/deleteHomePage", data);
}
// 删除主页组件
export function deleteHomePageComponent(data) {
  return axios.post("/home/deleteHomePageComponent", data);
}

//获取对象字段列表
export function getFieldList(objId) {
  return axios.post('/field/getFieldList', objId)
}
//获取视图列表
export function getviewlist(objId) {
  return axios.post('/view/list/getViewList', objId)
}

// ----------------------------------------------------------------------------------------------------------
/**
 * 简档主页
 */

// ----------------------------------------------------------------------------------------------------------
/***
 * *标准主页
 */
//审批中心
export function getApprovalList(data, CancelToken) {
  return axios.post("/home/getApprovalList", data, CancelToken);
}
//记录卡片-悬浮气泡
//获取对象详细信息
export function getDetail(data) {
  return axios.post("/objectdetail/getDetail", data);
}
//获取相关列表对象信息
export function getRelationList(data) {
  return axios.post("/objectdetail/getRelationList", data);
}
//获取相关列表数据及按钮和字段信息
export function getDetailRelatedItems(data) {
  return axios.post("objectdetail/getDetailRelatedItems", data);
}

// ----------------------------------------------------------------------------------------------------------
/**
 * 自定义主页
 */
// 获取简档主页列表
export function getProfileHomePageList(data) {
  return axios.post("/home/getProfileHomePageList", data);
}
// 获取简档主页分配
export function getProfileHomePageSetup(data) {
  return axios.post("/home/getProfileHomePageSetup", data);
}
// 保存简档主页分配
export function saveHomePageProfile(data) {
  return axios.post("/home/saveHomePageProfile", data);
}
// 保存主页基础信息
export function saveHomePage(data) {
  return axios.post("/home/saveHomePage", data);
}
// 复制主页
export function copyHomePage(data) {
  return axios.post("/home/copyHomePage", data);
} 