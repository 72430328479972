<template>
  <div class="body">
    <!-- 标题区域 -->
    <!-- 标准主页 -->
    <Header-card
      :selectTitleName="selectTitleName"
      :HomePageListData="HomePageListData"
      @refreshEvent="refreshEvent"
      :isAdmin="true"
      :rolePageDropDownName="rolePageDropDownName"
      :rolePageDropDownNameS="rolePageDropDownNameS"
      @screenFull="screenFull"
    />
    <!-- 内容区域 -->
    <Common-index
      class="common_index_div"
      :commonDataArr="commonDataArr"
      ref="CommonIndex"
    />
    <!-- 图表全屏组件 -->
    <chartFullScreen
      :chartFullScreenTo="chartFullScreenTo"
      :DashboardDataFullScreen="DashboardDataFullScreen"
      @chartFullScreenClose="chartFullScreenClose"
      :dataShowName="dataShowName"
    />
    <!-- 主页内容全屏弹框 -->
    <homePageFullScreen
      v-if="homePageFullScreenTo"
      :homePageFullScreenTo="homePageFullScreenTo"
      @homePageFullScreenClose="homePageFullScreenClose"
      :homePagetitle="$t('label_profile_mobile_setup_stand')"
    />
  </div>
</template>
<script>
import chartFullScreen from "@/components/FullScreen/chartFullScreen"; //图表全屏组件
import homePageFullScreen from "@/components/FullScreen/homePageFullScreen"; // 主页内容全屏组件
import { HeaderCard } from "../components/index";
import CommonIndex from "../components/CommonIndex";
import * as request from "./api.js";
import * as types from "@/store/mutations-types";
export default {
  components: {
    HeaderCard,
    CommonIndex,
    chartFullScreen,
    homePageFullScreen,
  },
  data() {
    return {
      homePageFullScreenTo: false, //主页内容是否全屏
      //isShowMymission: false, //是否显示新建任务弹框
      homePageObjectData: {}, //当前页面的对象(包含name,id,type)
      rolePageDropDownName: "",
      rolePageDropDownNameS: "", //标准主页的名称
      selectTitleName: "", //当前主页的名称
      HomePageListData: [], //全部主页对象列表
      /**
       * 公共数组，存储所有组件的数据
       */
      commonDataArr: [],
      //applicationId: this.$store.state.home.homeApplicationId, //应用程序id（监听使用，切换不同的应用程序让主页重新请求布局）
      chartFullScreenTo: false, // 图表是否全屏
      DashboardDataFullScreen: {},
      dataShowName: "", //图表name
      //auditCenterLength: 0, //审批中心当前页条数
      homePageLayout: [], //页面布局layout
    };
  },
  mounted() {
    this.$bus.$on("refareshPage", this.getHomePageLayoutDataWrap);
    this.$nextTick(() => {
      this.getHomePageLayoutData("standard");
    });
    //控制图表全屏展示
    this.$bus.$on("clickChartFullScreen", this.clickChartFullScreenFn);
    this.$bus.$on("audit_table_boxparams", this.audit_table_boxparamsFn);
  },
  beforeDestroy() {
    this.$bus.$off("refareshPage", this.getHomePageLayoutDataWrap);
    this.$bus.$off("clickChartFullScreen", this.clickChartFullScreenFn);
    this.$bus.$off("audit_table_boxparams", this.audit_table_boxparamsFn);
  },
  methods: {
    clickChartFullScreenFn(e, dataShowName) {
      this.DashboardDataFullScreen = e; //图表数据
      this.chartFullScreenTo = true;
      this.dataShowName = dataShowName; //图表name
    },
    audit_table_boxparamsFn(payload) {
      this.audit_table_boxparams = payload;
      this.commonDataArr = this.dynamicLayout(this.homePageLayout);
    },
    //单个图表的全屏关闭
    chartFullScreenClose() {
      this.chartFullScreenTo = false;
    },
    //使标准主页内容区域全屏
    screenFull() {
      this.homePageFullScreenTo = true;
      setTimeout(() => {
        this.$bus.$emit("screenFullStandardData", this.commonDataArr);
      }, 0);
    },
    //关闭全屏弹框
    homePageFullScreenClose() {
      this.homePageFullScreenTo = false;
    },
    getHomePageLayoutDataWrap() {
      this.getHomePageLayoutData("standard");
    },
    // 获取主页布局数据
    async getHomePageLayoutData(pageType) {
      const {
        HomePageListData,
        homePageObjectData,
        rolePageDropDownNameObject,
      } = await request.getHomePageListToCurrentPageObjectData(pageType);
      this.HomePageListData = HomePageListData;
      let RecentViewPage = false; //判断是否有最近查看页面
      this.HomePageListData.find((item) => {
        if (item.defaulthomepageid) {
          RecentViewPage = true;
        }
      });

      this.homePageObjectData = homePageObjectData;
      // 传值给标准主页
      this.rolePageDropDownNameS = HomePageListData
        ? HomePageListData[0].name
        : "";
      document.title = this.$setTitle(homePageObjectData.name);
      this.$store.commit(types.SET_TAB_NAME, homePageObjectData.name);
      this.selectTitleName = homePageObjectData.name;

      /*
       * 跳转主页，如果是在右上角切换标准主页，则不需要做该判断；
       * 1.默认主页顺序：（最近查看主页、应用程序主页、简档主页、标准主页）
       * 2.最近查看主页：根据defaulthomepageid判断有没有
       * 3.应用程序主页：type==standard&&name！=标准主页
       * 4.简档主页：type == profile &&isdefault=="true"
       * 5.标准主页：：type==standard&&name==标准主页
       */
      if (
        this.$cookies.get("clickToPage") !== "standardPage" &&
        this.$route.path === "/homePageObject/standardPage"
      ) {
        // 有最近查看主页：（最近查看主页、应用程序主页、简档主页、标准主页）
        if (RecentViewPage) {
          this.HomePageListData.find((item) => {
            if (item.defaulthomepageid) {
              if (item.type == "standard") {
                this.$router.push("standardPage");
                return true;
              }
              if (item.type == "personal") {
                this.$router.push("personalPage");
                return true;
              }
              if (item.type == "profile") {
                this.$router.push("rolePage");
                return true;
              }
            }
          });
        } else {
          // 无最近查看主页：（应用程序主页、简档主页、标准主页）
          this.HomePageListData.find((item) => {
            if (
              item.type == "standard" &&
              item.name != this.$i18n.t("label_profile_mobile_setup_stand")
            ) {
              this.$router.push("standardPage");
              return true;
            }
            if (item.type == "profile" && item.isdefault == "true") {
              this.$router.push("rolePage");
              return true;
            }
          });
        }
      }
      this.$cookies.set("clickToPage", "", { sameSite: 'Strict' });
      // 获取主页组件布局
      const { data } = await request.getHomePageLayout({
        id: this.homePageObjectData.id,
        iseditLayout: "false", // 表示不是走的编辑自定义主页，这时候需要记录当前主页
      });

      if (!data?.data) {
        return;
      }
      // 将首次返回的页面布局暂存，此处不使用动态布局
      this.homePageLayout = data?.data;
      this.commonDataArr = this.homePageLayout;
      this.rolePageDropDownName = rolePageDropDownNameObject
        ? rolePageDropDownNameObject.name
        : "";
    },
    // 全局刷新
    refreshEvent() {
      this.$bus.$emit("refreshOverallData");
    },
    dynamicLayout(dataArr) {
      return dataArr.map((item) => {
        if (item.relatedid.indexOf("spzx") !== -1) {
          /**
           * 使用审批中心卡片的数据和当前tab去动态修改页面级卡片单元格高度
           * 原则:
           *     如果计算的单元格高度超过用户设置的单元格高度,
           *      则使用用户设置的单元格高度,否则使用动态计算的单元格高度,
           *     但是不包括 all 的全部tab栏
           */
          let { domHeight, currentTab, totalnum } = this.audit_table_boxparams;
          if (domHeight) {
            /**
             * 如果卡片级数据有传递数据,
             * 则向上取整卡片的计算高度,修改为页面布局高度
             */
            let AuditDynamiclayoutCardHeight = Math.ceil(domHeight / 80);
            if (AuditDynamiclayoutCardHeight >= item.h) {
              return { ...item, h: item.h };
            } else {
              /**
               * 如果计算出来的高度超过用户设置的卡片高度
               * 则强制使用用户设置的高度,而不能使用动态计算高度
               */
              if (AuditDynamiclayoutCardHeight + 2 >= item.h) {
                return item;
              } else {
                /**
                 * 如果是全部tab 则不走动态高度判断
                 * 走原始用户设置的卡片高度
                 */
                if (currentTab === "all") {
                  if (totalnum === 5) {
                    return item;
                  } else {
                    return { ...item, h: AuditDynamiclayoutCardHeight + 2 };
                  }

                  // totalnum
                } else {
                  /**
                   * 如果不是全部,并且计算出来的高度没有超过设置的卡片高度
                   * 则使用动态高度(保留2格作用于表头和表底大小)
                   */
                  return { ...item, h: AuditDynamiclayoutCardHeight + 2 };
                }
              }
            }
          } else {
            if (totalnum === 0) {
              return { ...item, h: 3 };
            } else {
              return item;
            }
          }
        } else {
          return item;
        }
      });
    },
  },
  destroyed() {
    this.$bus.$off("clickChartFullScreen");
    this.$bus.$off("refareshPage");
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 10px;
}
.common_index_div {
  /* margin-top: 60px; */
}
::v-deep .gt-gantt-content-wrap::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  opacity: 0;
  background: #fff;
}
::v-deep .gt-gantt-content-wrap {
  overflow: auto;
}
::v-deep .gt-gantt-content-wrap::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::v-deep .gt-gantt-content-wrap:hover {
  padding-bottom: 1px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
}
</style>
